@import "../master.scss";
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
.intro {
  min-width: 0;
  width: 100vw!important;
  height: 100vh;
  padding: 0% !important;
  background: $prim-green;
  color: var(--color-white);
  font-family: 'Open Sans', sans-serif!important;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  
}
.intro .img-row .intro-img {
  position: relative;
  padding: 0px;
  border-radius: 50%;
  border: 3px solid $prim-green;
  width: 25rem;
  height: auto;
  margin-left: 10%
  
}
.typewritter-row{
  padding-top: 10px;
  display: flex;
  position: relative;
}

@media (max-width: 1299px) {

  
  .typewritter-row {
    padding-top: 10px;
    display: flex;
    position: relative;
    justify-content: center; /* Add this line to horizontally center the content */
  }
}


.name-row{
  margin: auto;
  width: 100%;
  
}

.lastname{
  padding-left: 15px;
  color: $prim-magenta;
}

.name{ 
  display: flex;
  float: left;
  text-align: left;
  font-size: 40px;
  position: relative;
  color: $prim-cultured;
  font-weight: bold;
  
}

.type-col{
  display: flex;

  align-items: center;
}
.img-row {
  text-align: center;
  width: 100%;
  position: relative;
  margin: auto;
}
.typewriter {
  color: $prim-cultured;
  font-size: 25px;
  opacity: .7;

  font-weight: bold;
}
.cursor {
  color: $prim-grey;
  font-size: 25px;

  font-weight: bold;
}

@media only screen and (max-width: 1299px) {
  .intro .img-row .intro-img {
    position: relative;
    padding: 0px;
    border-radius: 50%;
    border: 3px solid $prim-green;
    max-width: 250px;
    height: auto;
    margin-left: 0%
    
  }
  .name{ 
    display:unset;
    float:none;
    text-align: center;
    font-size: 30px;
    
    position:relative;
    color: $prim-cultured;
    font-weight: bold;
  }
  .name-row{
    
  }
  .typewritter-row{
    padding-top: 10px;
    display: flex;
    position: relative;
    width: 100%;
  }

.type-col{
  display:inline;

  align-items: center;
}
.lastname{
  padding-left: 0px;
  color: $prim-magenta;
}
}