@import "/src/styles/master.scss";
$sidebar-width: 100% !default;
$sidebar-bg-color: #05455c;
.sticky-container {
  height: 100vh;
  z-index: 5;
  border-top: 2px solid black;
  border-right: 2px solid black;
}
.sidebar {
  align-items: center;

  background-color: #05455c;
  width: 100%;
  height: 100vh;
  
}
.header-side {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.elephant{ 


    width: 25vh;
}

.links-side {
  justify-content: center;
  align-items: center;
  height: 8vh;
}
.text-inner {
  position: relative;
  padding-left: 30px;
  float: left;
  color: $prim-cultured;
  font-size: 1.8rem;
}
a {
  color: $prim-cultured;
  &:hover {
    color: $prim-magenta;
    
    
  }
}
a:link {
  text-decoration: none;
}
a:hover{
    
}

@import "~react-pro-sidebar/dist/scss/styles.scss";
