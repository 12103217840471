@import "styles/master.scss";
.Project-card-content {
  width: 100%;
  height: 100%;
  background-color:  $prim-green!important;
  color: $prim-grey !important;
  border-radius: 20px;
  position: relative;
  border: 3px solid black;
  
  z-index: 10000;
  
  transition: all 500ms ease;
  
}
.MuiTab-textColorPrimary.Mui-selected{
  color: $prim-magenta!important;
}

.media {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.media img {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.proj-content{ 
  padding: 0%;
  margin: auto;
  font-size: 1rem;
  color: $prim-cultured;
}
.media{
  transform: skew(18deg);
  position: relative;
  z-index: -1;
  height: 25vh;
  margin: 10px;
}
.card-content{ 
  margin: 10px;
  position: relative;
  z-index: 999;
  height: 150px;

}
.link-button{
  position: relative;
  z-index: 99999;
}
  

.Outer-Project-card-content {
  margin: auto;
  width: 100%;
  z-index: -1;
  position:relative;
  transform: skew(-18deg);
  padding-bottom: 10px;
  padding-right: 10px;
  position: relative;
  z-index: 99999;
 
}


.content-container{ 

  padding: 0; 

}

.header-row{ 

  padding: 0;
}
.Outer-Project-card-content:after {

  content: "";
  
  position: absolute;
  left: 0;
  bottom: 0;
  border-bottom-right-radius: 8px;
  height: 92%;
  width: 100%;

  z-index: 1;

  // border-bottom: 2px solid $prim-magenta;
  border-right: 2px solid $prim-magenta;

  
}

.slides{
  display: inline;
}


.unskew-Project-card-content {
  padding-left: 10px;
  transform: skew(0deg);
}

@media only screen and (max-width: 600px) {
  .unskew-Project-card-content {
    padding-left: 0px;
    transform: skew(0deg);
  }

  .media{
    transform: skew(0deg);
    position: relative;
    z-index: -1;
    height: auto;
    width: 100%;
    
  }
  .card-content{ 
    margin: 10px;
    position: relative;
    z-index: 999;
    height: 100%;
  
  }
  .Outer-Project-card-content {
    margin: auto;
    width: 80%;
    z-index: -1;
    position:relative;
    transform: skew(0deg);
    padding-bottom: 10px;
    padding-right: 10px;
    position: relative;
    z-index: 99999;

   
  }

  .Outer-Project-card-content:after {

    content: "";
    
    position: absolute;
    left: 0;
    bottom: 0;
    border-bottom-right-radius: 8px;
    height: 80%;
    width: 90%;
    z-index: 1;
  
    // border-bottom: 2px solid $prim-magenta;
    border-right: 2px solid $prim-magenta;
    
  }

}

