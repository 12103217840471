@import "styles/master.scss";
.NavMobile{
    background-color: $prim-green!important;
    position: relative;
    z-index: 9999;

} 

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,102,203, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
  
  .custom-toggler.navbar-toggler {
    border-color: rgb(255,102,203);
  } 

// .navbar-toggler-icon {
//     background-color: $prim-magenta;
//     // color: $prim-magenta; /* Optional: Set text color to white or any other suitable color */
//   }


    